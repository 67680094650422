<script src="https://unpkg.com/vue-router/dist/vue-router.js"></script>
<template>
<div id="app">
  <v-app>
    <core-app-bar />
    <core-drawer />
    <v-card height="40"> </v-card>
    <router-view></router-view>
    <core-footer />
  </v-app>
</div>
</template>

<script>
import Card from './components/base/Card.vue';

export default {
  name: 'App',

  components: {
    // CoreCta: () => import('@/components/core/Cta'),
    CoreDrawer: () => import('@/components/core/Drawer'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreAppBar: () => import('@/components/core/AppBar')
  },

  data: () => ({
  }),
};
</script>


<style>
@font-face {
  font-family: "Caesar Dressing";
  src: local("Caesar Dressing"),
   url(./fonts/CaesarDressing-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Jim Nightshade";
  src: local("Jim Nightshade"),
   url(./fonts/JimNightshade-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oi";
  src: local("Oi"),
   url(./fonts/Oi-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Guadalupe";
  src: local("Guadalupe"),
   url(./fonts/Guadalupe.ttf) format("truetype");
}
@font-face {
  font-family: "Higheat";
  src: local("Higheat"),
   url(./fonts/HigheatRegular.ttf) format("truetype");
}
@font-face {
  font-family: "Barriecito";
  src: local("Barriecito"),
   url(./fonts/Barriecito-Regular.ttf) format("truetype");
}
</style>