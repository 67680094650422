<template>
    <v-container full-width>
        <v-card height="70" elevation="0"/>
        <v-row no-gutters>
            <v-card elevation="20" :height="show ? 1000 : 600">
                <v-list-item>
                    <v-list-item-avatar
                    tile
                    size="580"
                    >
                        <v-img
                        :min-height="'calc(100vh - ' + $vuetify.application.top + 10 + 'px)'"
                        contain=false
                        :src = "require('@/assets/img/gallery/large/' + getStoryImg())"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-card-title>
                            <p>{{ getStoryTitle() }}</p>
                        </v-card-title>
                        <v-card-text class="mx-auto font-weight-light mb-8">{{ getStory() }}</v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="deep-purple lighten-2"
                                text
                                @click = gotoHome()>Home</v-btn>
                            <v-spacer />
                            <v-btn icon @click="show = !show">
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="show" class="font-weight-light">
                                <v-divider></v-divider>
                                <v-card-text class="mx-auto font-weight-light mb-8">{{ getStoryExpanded() }}</v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-row>
    </v-container>
</template>


<script>
  import {
    mapState,
    mapGetters,
  } from 'vuex'

  export default {
    name: "Story",
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: false,
        widgets: false,
        show: false
      }
    },
    computed: {
      ...mapState(['stories']),
      ...mapGetters(['getStoryById']),
      getStoryComputed () {
          return this.$store.state.stories[0].story
      }
    },
    methods: {
        getImageSrc() {
            return "assets/img/gallery/" + this.$route.params.storyId
        },
        getStory () {
          return this.$store.state.stories.filter(s => this.$route.params.storyId.includes(s.id))[0].story
        },
        getStoryTitle () {
          return this.$store.state.stories.filter(s => this.$route.params.storyId.includes(s.id))[0].title
        },
        getStoryImg () {
          return this.$store.state.stories.filter(s => this.$route.params.storyId.includes(s.id))[0].img
        },
        getStoryExpanded () {
          return this.$store.state.stories.filter(s => this.$route.params.storyId.includes(s.id))[0].expanded
        },
        gotoHome() {
          this.$router.push('/')
        }
    }
  }
</script>

<style scoped>
  .font-weight-light {
     font-family: "Times", Helvetica, Arial !important;
     font-size: 1em;
     color: black !important;
     /* text-shadow: 1px 1px #eeeeee !important; */
  }
</style>