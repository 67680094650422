<template>
    <v-main style="background: white;" class="pa-0 ma-0">
      <section id="hero">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="@/assets/img/NYCSkylinePano.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center"
                    cols="12"
                    tag="h1"
                  >
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light"
                    >
                      WELCOME TO
                    </span>

                    <br>

                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                      class="font-weight-black"
                    >
                      SPDP
                    </span>

                  </v-col>

                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about-me')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">ABOUT ME</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            I love taking photos and making videos, it is a great passion of mine. I like to make and tell stories through my art, "spdp" is an effort to reach out to people who enjoy stories.
          </v-responsive>

          <v-avatar
            class="elevation-12 mb-12"
            size="128"
          >
            <v-img src="@/assets/img/sunil.jpeg"></v-img>
          </v-avatar>

          <div></div>

          <a href="https://www.facebook.com/Spdp-uncut-105480088381988" style="text-decoration: none;">
          <v-icon color="blue" click="next">
            mdi-facebook
          </v-icon>
          </a>
          <a href="https://www.instagram.com/spdp.uncut/" style="text-decoration: none;">
          <v-icon color="red">
            mdi-instagram
          </v-icon>
          </a>
        </v-container>

        <div class="py-12"></div>
      </section>
      <feed />
      <v-card flat
        :loading="loading"
        class="mx-auto my-12"
        max-width="80%">
        <div class="text--primary">
          <v-card-text>Photography is the art of expressing your imagination, whereas cinematography is about expressing your emotions.
            In both the cases, the end goal is to stimulate some emotions in the viewers heart. If I can make someone go "wow" with my
            photographs and bring joy or tear with my videos, my job as an artist is done.
            In any art form there are multiple things an artist needs to learn and master, like the craft. Craft is a collective
            term for both tools and technology. You need to know you tools, in today's world that would be both your camera gear
            like your actual camera, lenses, filters, lights, softboxes, colorcard etc. and your software you use to post process
            your raw images or videos. Craft also includes knowing various techniques to capture different kind of images and videos
            in different conditions, how to modify your settings or lights or angles to capture certain moods or depth or effects.
            Once you know these things in details then you can apply these to capture whatever kind of creative images you can come up
            with, that's where the art in an artist comes out. Once you know the craft, you know how to use your tools and how to bend
            rules to create an image, you will be able to be very creative in your imagination as well. Here are a few basic things you
            need to know no matter what camera or lens or software you use -<br><br>
            <h5>1. Exposure</h5><br>
            <v-card-text>Exposure represents how bright or dark you want various elements in your frame to look. In Auto mode camera tries
              optimize this aspect for the whole frame and manages aperture, ISO and shutter speed when you click the camera button. This also
              intorduces to the three main properties of you camera that affects exposure of your pictures.
            </v-card-text>
            <h5>2. Sharpness</h5><br>
            <v-card-text>
              Sharpness is probably the most important characterstic of a picture that you need to absolutely take care of while taking a
              picture, if your picture is captured sharp there is very little you can do in post and no matter how creative your frame was
              if certain elements are not sharp enough it is not going to look good. This is also difficult because most camera screens show 
              pictures sharp due to their resolution but when you see it on a larger screen you get the real output.
            </v-card-text>
            <h5>3. Depth of field (Aperture)</h5><br>
            <v-card-text>
              This is a nice way of telling the viewers where you want them to look for the real content, sometimes it is actually necessary
              otherwise there is just too much stuff in your frame to distract the viewers and making a real good picture look not so impressive.
              Other times it is all about your creativity to separate the foreground from background and putting things in perspective. One more
              thing it does nicely which is otherwise impossible in 2D pictures is that it gives your pictures some depth which is very natural
              for our eyes to see in a 3D world.
            </v-card-text>
            <h5>4. Media Quality</h5><br>
            <v-card-text>
              This basically determines how far can you stretch your pictures either in post processing or while printing. It will mean how enlarged
              you want your print to be. In modern day digital camera it means the sensor size and resolution plus the capture mode where you will be
              able to do much more in post processing if you are using a raw file format vs if you are using a compressed file format like jpeg.
            </v-card-text>
          </v-card-text>
        </div>
      </v-card>
    </v-main>
</template>


<script>
  export default {
    name: 'HomePage',

    components: {
      Feed: () => import('@/components/Feed'),
      // BaseBtn: () => import('@/components/base/Btn'),
    },
  }
</script>

<style scoped>
  .font-weight-light.display-2 {
     /* font-family: "Caesar Dressing", Helvetica, Arial !important; */
     font-family: "Barriecito", Helvetica, Arial !important;
     color: #eeeeee !important;
     text-shadow: 2px 2px black !important;
  }
 
  .font-weight-light.display-1 {
     /* font-family: "Caesar Dressing", Helvetica, Arial !important; */
     font-family: "Barriecito", Helvetica, Arial !important;
     color: #eeeeee !important;
     text-shadow: 2px 2px black !important;
  }

  .font-weight-black.display-3 {
     /* font-family: "Caesar Dressing", Helvetica, Arial !important; */
     /* font-family: "Barriecito", Helvetica, Arial !important; */
     /* font-weight: 20 !important; */
     color: #eeeeee !important;
     text-shadow: 2px 2px black !important;
  }

  .font-weight-black.display-4 {
     /* font-family: "Barriecito", Helvetica, Arial !important; */
     /* font-family: "Barriecito", Helvetica, Arial !important; */
     /* font-weight: 20 !important; */
     color: #eeeeee !important;
     text-shadow: 2px 2px black !important;
  }

  .display-2 {
    font-family: "Barriecito", Helvetica, Arial !important;
    font-size: 2.0rem !important;
  }
</style>
