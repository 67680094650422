import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
import About from './components/About.vue'
import HomePage from './components/HomePage.vue'
import Story from './components/stories/Story.vue'
import Stories from './components/stories/Stories.vue'
import ImageViewer from './components/ImageViewer.vue'


Vue.config.productionTip = false
Vue.use(vuetify)
Vue.use(VueRouter)

const routes = [
  { path: '/', component: HomePage, name: 'home' },
  { path: '/about', component: About, name: 'about' },
  { path: '/story/:storyId', component: Story, name: 'story' },
  { path: '/stories', component: Stories, name: 'stories' },
  { path: '/imageviewer/:imageSrc', component: ImageViewer, name: 'imageviewer' }
]

const router = new VueRouter({ routes: routes, mode: 'history' })

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
