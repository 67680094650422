<template>
<section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">ABOUT ME</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            I love taking photos and making videos, it is a great passion of mine. I like to make and tell stories through my art, "spdp" is an effort to reach out to people who enjoy stories.
          </v-responsive>

          <v-avatar
            class="elevation-12 mb-12"
            size="128"
          >
            <v-img src="@/assets/img/sunil.jpeg"></v-img>
          </v-avatar>

          <div></div>

          <a href="https://www.facebook.com/Spdp-uncut-105480088381988" style="text-decoration: none;">
          <v-icon color="blue" click="next">
            mdi-facebook
          </v-icon>
          </a>
          <a href="https://www.instagram.com/spdp.uncut/" style="text-decoration: none;">
          <v-icon color="red">
            mdi-instagram
          </v-icon>
          </a>
        </v-container>

        <div class="py-12"></div>
      </section>
</template>

<script>
  export default {
    name: 'About',
    data: () => ({
      width: 400,
    }),
  }
</script>