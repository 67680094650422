<template>
  <div class="d-flex flex-column justify-space-between align-center align-self-stretch" style="height: 100%;">
    <v-card height="70" />
    <!-- <h1>{{ $route.params.imageSrc }}</h1> -->
    <v-slider
      v-model="width"
      class="align-self-stretch"
      min="400"
      max="800"
      step="1"
    ></v-slider>

    <v-img
      :width="width"
      :src="require('@/assets/img/gallery/large/' + $route.params.imageSrc)"
    ></v-img>
  </div>
</template>

<script>
  export default {
    name: 'ImageViewer',
    data: () => ({
      width: 400,
    }),
  }
</script>