<template>
    <v-container full-width>
        <v-card height="70" elevation="0"/>
        <v-row no-gutters v-for="story in getStories" :key="story.id">
            <v-card elevation="20" height="900">
                <v-list-item>
                    <v-list-item-avatar
                    tile
                    size="580"
                    >
                        <v-img contain=false
                        :min-height="'calc(100vh - ' + $vuetify.application.top + 10 + 'px)'"
                        :src = "require('@/assets/img/gallery/large/' + story.img)"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-card-title>
                            <p>{{ story.title }}</p>
                        </v-card-title>
                        <v-card-text class="mx-auto font-weight-light mb-8">{{ story.story }}</v-card-text>
                    </v-list-item-content>
                </v-list-item>
                <div class="font-weight-light">
                    <v-divider></v-divider>
                    <v-card-text class="mx-0 font-weight-light mb-8">
                        <p>{{ story.expanded }}</p>
                        <p>{{ story.additional }}</p>
                    </v-card-text>
                </div>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
// import Story from '@components/stories/Story'
import {mapState, mapGetters} from 'vuex'

export default {
    name: "Stories",
    data () {
      return {
        show: false,
        crop: false
      }
    },
    computed: {
      ...mapState(['stories']),
      ...mapGetters(['getStories']),
      getStories () {
          return this.$store.state.stories
      }
    },
}
</script>
